export default async function getproductos(pais, catalogo, lista) {
  let datos;

  await fetch(`/api/productos/${pais}/${catalogo}/${lista}`)
    .then((res) => res.json())
    .then((data) => {
      datos = data;
    });
  return datos;
}
