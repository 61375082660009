// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_APP_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://45c068b2f2894e82a054c2ae74cd8478@o1055295.ingest.sentry.io/6041273',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  environment,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.type === 'debug') return null;
    if (breadcrumb.category === 'console') return null;
    return breadcrumb;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
