export default async function getpagos(sucursal) {
  let datos;

  await fetch(`/api/pagos/${sucursal}`)
    .then((res) => res.json())
    .then((data) => {
      datos = data;
    });
  return datos;
}
