import firebase from 'firebase';
import getfechaanterior from 'services/getfechaanterior';

var firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);

!firebase.apps.length && firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const hoy = new Date();

export const loginWithEmail = async (email, pass) => {
  const user = await firebase
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch((error) => {
      return false;
    });
  return user;
};

export const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      window.location.reload(false);
    })
    .catch((error) => console.log(error));
};

//Sucursales

export const datosSucursal = async (id) => {
  let sucursal;
  await db
    .collection('sucursales')
    .doc(id)
    .get()
    .then((doc) => {
      sucursal = doc.data();
    });

  return sucursal;
};

// actualizar Estado de un Pedido

export const actualizarEstadoPedido = async (
  pais,
  pedido,
  estado,
  detalle_anulado
) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidos')
    .doc(pedido)
    .update({
      estado: estado,
      detalle_anulado: detalle_anulado,
      cambio_estado_manager: {
        fecha: new Date(),
        nuevo_estado: estado
      }
    });
  return;
};

// actualizar Carrito de un Pedido

export const actualizarCarrito = async (
  pais,
  pedido,
  descuento,
  carrito,
  total,
  detalle
) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidos')
    .doc(pedido)
    .update({
      carrito: carrito,
      total: total,
      detalle: detalle,
      descuento: descuento,
      total_con_descuento: total - descuento,
    });
  return;
};

// Pedidos en tiempo real

const mapPedidos = (doc) => {
  let pedidos = [];
  doc.map((singleDoc) => {
    const data = singleDoc.data();
    const id = singleDoc.id;
    pedidos.push({ id, data });
  });
  return pedidos;
};

export const listadoPedidosRealTime = (datos, callback) => {
  //Acortar búsqueda de Alba
  datos.sucursal === 'dealba' ?
  db
    .collection('paises')
    .doc(datos.pais)
    .collection('pedidos')
    .where('sucursal', '==', datos.sucursal)
    .where('fecha', '>=', getfechaanterior(60))
    .onSnapshot(({ docs }) => {
      const pedidos = mapPedidos(docs);
      callback(pedidos);
    }) :
    db
    .collection('paises')
    .doc(datos.pais)
    .collection('pedidos')
    .where('sucursal', '==', datos.sucursal)
    .onSnapshot(({ docs }) => {
      const pedidos = mapPedidos(docs);
      callback(pedidos);
    })
};

// Recibir Pago con comprobante

export const pagoRealizado = async (sucursal, fecha, valor, comprobante, imagen_l, imagen_m, imagen_s, comment, cantidad_pendientes, monto_pagos_pendientes, administracion) => {
  await db
  .collection('sucursales')
  .doc(sucursal)
  .collection('pagos')
  .add({
    fecha: fecha,
    valor: valor,
    comprobante: comprobante,
    comprobante_l: imagen_l,
    comprobante_m: imagen_m,
    comprobante_s: imagen_s,
    estado: 'pendiente',
    comentario: comment
  })
  await db
  .collection('sucursales')
  .doc(sucursal)
  .update({
    administracion: {
      ultima_fecha_aprobado: administracion.ultima_fecha_aprobado !== null ? new Date(administracion.ultima_fecha_aprobado.seconds * 1000) : null,
      ultima_fecha_pendiente: new Date(),
      pagos_pendientes: cantidad_pendientes + 1,
      monto_pendiente: monto_pagos_pendientes + valor,
      ultimo_pago_aprobado: administracion.ultimo_pago_aprobado
    }
  })
  return true
}

export const crearPagoMercadoPago = async (sucursal, preference_id, valor) => {
  let respuesta = false
  await db
  .collection('sucursales')
  .doc(sucursal)
  .collection('pagos')
  .add({
    comprobante: 'https://firebasestorage.googleapis.com/v0/b/nilus-prod.appspot.com/o/imgs%2Fpagos%2Fmercado-pago.png?alt=media&token=b21a4b99-ac87-4ae5-a303-6537ada8983a',
    comprobante_l: 'https://firebasestorage.googleapis.com/v0/b/nilus-prod.appspot.com/o/imgs%2Fpagos%2Fmercado-pago.png?alt=media&token=b21a4b99-ac87-4ae5-a303-6537ada8983a',
    comprobante_m: 'https://firebasestorage.googleapis.com/v0/b/nilus-prod.appspot.com/o/imgs%2Fpagos%2Fmercado-pago.png?alt=media&token=b21a4b99-ac87-4ae5-a303-6537ada8983a',
    comprobante_s: 'https://firebasestorage.googleapis.com/v0/b/nilus-prod.appspot.com/o/imgs%2Fpagos%2Fmercado-pago.png?alt=media&token=b21a4b99-ac87-4ae5-a303-6537ada8983a',
    preference_id: preference_id,
    estado: 'pendiente',
    medio: 'link mercadopago',
    fecha: new Date(),
    valor: parseFloat(valor)
  })
  .then(docRef => {
    respuesta = docRef.id
  })
  return respuesta
}

export const actualizarPagoMercadoPago = async (sucursal, collection_status, payment_id, status, payment_type, merchant_order_id, preference_id) => {
  let sucursalesRef = db.collection('sucursales')
  let pagosRef = await sucursalesRef.doc(sucursal).collection('pagos').where('preference_id', '==', preference_id).get()
  for(let pago of pagosRef.docs) {
    let actualizarPago = await sucursalesRef.doc(sucursal).collection('pagos').doc(pago.id).update({
      estado: status === 'approved' ? 'aprobado' : status === 'pending' ? 'pendiente' : 'rechazado',
      fecha_actualizacion: new Date(),
      mercado_pago: {
        collection_status, 
        payment_id, 
        status, 
        payment_type, 
        merchant_order_id, 
        preference_id
      }
    })
    actualizarPago
  }
  return true
}


export const estadoPagoMercadoPago = async (sucursal, preference_id) => {
  let estado
  let sucursalesRef = db.collection('sucursales')
  let pagosRef = await sucursalesRef.doc(sucursal).collection('pagos').where('preference_id', '==', preference_id).get()
  for(let pago of pagosRef.docs) {
    estado = pago.data().estado
  }
  return estado
} 