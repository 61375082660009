import React from 'react';
import { useState, useEffect } from 'react';
import {
  actualizarCarrito,
  actualizarEstadoPedido,
  datosSucursal,
  listadoPedidosRealTime,
  logout,
} from 'firebase/client';

import { useRouter } from 'next/router';
import firebase from 'firebase';
import getproductos from 'services/getproductos';
import getpagos from 'services/getpagos';
import getfacturas from 'services/getfacturas';

let AdminContext = React.createContext();
let { Provider, Consumer } = AdminContext;
function AdminProvider({ children }) {
  const [pais, setPais] = useState('');
  const [sucursal, setSucursal] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState(null);
  const [facturas, setFacturas] = useState(null);
  const [realizoPago, setRealizoPago] = useState(null);
  const [pagos, setPagos] = useState(null);
  const [fechaEntrega, setFechaEntrega] = useState(null);
  const [email, setEmail] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const [usuarioLogueado, setUsuarioLogueado] = useState(false);
  const [allPedidos, setAllPedidos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actualizar, setActualizar] = useState(false);
  const [productos, setProductos] = useState(null);
  const [indexFecha, setIndexFecha] = useState(null);
  const [isShowOptions, setIsShowOptions] = useState(false);
  const [carrito, setCarrito] = useState([]);
  const [totalDescuento, setTotalDescuento] = useState(null);
  const [total, setTotal] = useState(null);
  const [detalle, setDetalle] = useState(null);
  const [clickeable, setClickeable] = useState(true);
  const [anulado, setAnulado] = useState(false);
  const [modalAgregarProductos, setModalAgregarProductos] = useState(false);
  const [pedidoActual, SetPedidoActual] = useState(null);
  const [esperar, setEsperar] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [modalComprobante, setModalComprobante] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [modalState, setModalState] = useState(false);
  const [modalDeleteProducts, setModalDeleteProducts] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState({});
  const [montoCupon, setMontoCupon] = useState(null);
  const [checkedBills, setCheckedBills] = useState([]);

  const router = useRouter();

  useEffect(() => {
    if (sucursal !== null) {
      let today = parseInt(new Date() / 1000);
      let j = 0;
      if (sucursal.data.fechas_entrega !== undefined) {
        for (let i = 0; i < sucursal?.data.fechas_entrega.length; i++) {
          if (today <= sucursal?.data.fechas_entrega[i].seconds && j === 0) {
            setIndexFecha(i);
            j = 1;
          } else {
            setIndexFecha(sucursal?.data.fechas_entrega.length - 1);
          }
        }
      }
    }
  }, [sucursal]);

  useEffect(() => {
    nombreSucursal !== null && (realizoPago === null || realizoPago === true) && 
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          datosSucursal(user.uid).then((res) => {
            setSucursal({ id: user.uid, data: res });
            setPais(res.pais);
            setUsuarioLogueado(true);
            setLoading(false);
          });
        } else {
          setLoading(false);
          setUsuarioLogueado(false);
          router.push(`/${nombreSucursal}`);
        }
      });
  }, [nombreSucursal]);

  // Productos de la sucursal

  useEffect(() => {
    sucursal !== null &&
      pais !== '' &&
      getproductos(
        pais,
        sucursal.data.catalogo,
        sucursal.data.lista_de_precio
      ).then((res) => {
        setProductos(res);
      });
  }, [pais, sucursal, realizoPago]);

  // Pedidos

  useEffect(() => {
    sucursal !== null &&
      pais !== '' &&
      listadoPedidosRealTime({ pais, sucursal: sucursal.data.url }, (docs) => {
        setAllPedidos(docs);
        fechaEntrega !== null &&
          setPedidos(
            docs.filter(
              (element) => element.data.fecha_entrega.seconds === fechaEntrega
            )
          );
        setClickeable(true);
        setActualizar(false);
      });
  }, [sucursal, fechaEntrega, pais, modalAgregarProductos]);

  // Facturas

  useEffect(() => {
    pais !== '' &&
      sucursal !== null && (realizoPago === null || realizoPago === true) &&
      getfacturas(pais, sucursal.id, sucursal.data.whitelist !== undefined ? sucursal.data.whitelist : false).then((res) => {
        setFacturas(res)
        setRealizoPago(false)
      })
  }, [pais, sucursal, actualizar, realizoPago]);

  // useEffect(() => {
  //   sucursal !== null &&
  //     fechaEntrega !== null &&
  //     fetch(
  //       `/api/pedidos/${sucursal.data.pais}/${sucursal.data.url}?fecha_entrega=${fechaEntrega}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => setPedidos(data))
  //       .then(() => setActualizar(false))
  //       .then(() => setClickeable(true));
  // }, [sucursal, actualizar, fechaEntrega]);

  //Agregar productos al carrito
  const agregarProductoEnPedido = async (item, pedido, detalle, descuento) => {
    let carritoActual = carrito;
    carritoActual.push(item);
    setCarrito(carritoActual);
    let nuevoDetalle =
      detalle +
      `| ${
        item.medida === 'gr'
          ? item.cantidad * item.escala < 1000
            ? item.cantidad * item.escala
            : (item.cantidad * item.escala) / 1000
          : item.cantidad * item.escala
      } ${
        item.medida === 'gr' &&
        (item.cantidad + item.cantidad) * item.escala >= 1000
          ? 'kg'
          : item.medida
      } x ${item.nombre}`;
    setTotal(
      parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
    );
    actualizarCarrito(
      pais,
      pedido,
      descuento,
      carrito,
      parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2)),
      nuevoDetalle
    ).then(() => {
      setActualizar(true);
    });
  };

  const agregarProductosEnPedido = async (
    productosAgregar,
    pedido,
    nuevoTotal,
    nuevoDetalle,
    descuento
  ) => {
    setTotal(nuevoTotal);
    await productosAgregar.forEach(async (element) => {
      let carritoActual = carrito;
      let newElement = {
        cantidad: 1,
        nombre: element.data.nombre,
        imagen: element.data.imagen,
        precio: parseFloat(
          element.descuento === true ? element.precio_descuento : element.precio
        ),
        id_erp_mx: element.id_erp_mx,
        productid: element.id,
        subtotal: parseFloat(
          element.descuento === true ? element.precio_descuento : element.precio
        ),
        medida: element.data.metodo,
        escala: element.data.escala,
        datos_producto: element.data,
      };

      carritoActual.push(newElement);

      await actualizarCarrito(
        pais,
        pedido,
        descuento,
        carritoActual,
        nuevoTotal,
        nuevoDetalle
      ).then(() => {
        setActualizar(true);
        setTotal(null);
      });
    });
    setModalAgregarProductos(false);
  };

  //Borrar Item Carrito

  const handleBorrar = (
    pedido,
    totalactual,
    productid,
    subtotalitem,
    descuento
  ) => {
    setClickeable(false);
    let carritoActual = carrito;
    let nuevoDetalle = '';
    carritoActual.forEach((element, index) => {
      if (element.productid === productid) {
        //se borra el producto del carrito
        carritoActual.splice(index, 1);
      }
    });

    carritoActual.forEach((element, index) => {
      nuevoDetalle =
        index === 0
          ? nuevoDetalle +
            `${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`
          : nuevoDetalle +
            ` | ${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`;
    });
    setCarrito(carritoActual);
    actualizarCarrito(
      pais,
      pedido,
      descuento,
      carrito,
      parseFloat(((totalactual * 100 - subtotalitem * 100) / 100).toFixed(2)),
      nuevoDetalle
    ).then(() => {
      setActualizar(true);
    });
  };

  // Agregar un producto al carrito y actualizarlo

  const nuevoCarrito = async (item, totalactual, pedido, descuento) => {
    let newElement;
    let carritoActual = carrito;

    //ya existe un producto en el carrito
    let nuevoDetalle = '';
    await carritoActual.forEach((element, index) => {
      if (element.productid === item.productid) {
        newElement = {
          cantidad: element.cantidad + item.cantidad,
          nombre: item.nombre,
          imagen: item.imagen,
          precio: item.precio,
          id_erp_mx: item.id_erp_mx,
          productid: item.productid,
          subtotal: element.subtotal + item.subtotal,
          medida: element.medida,
          escala: element.escala,
          datos_producto: item.datos_producto,
        };
        carritoActual.splice(index, 1, newElement);
        setCarrito(carritoActual);
        nuevoDetalle =
          index === 0
            ? nuevoDetalle +
              `${
                element.medida === 'gr'
                  ? (element.cantidad + item.cantidad) * element.escala < 1000
                    ? (element.cantidad + item.cantidad) * element.escala
                    : ((element.cantidad + item.cantidad) * element.escala) /
                      1000
                  : (element.cantidad + item.cantidad) * element.escala
              } ${
                item.medida === 'gr' &&
                (element.cantidad + item.cantidad) * item.escala >= 1000
                  ? 'kg'
                  : item.medida
              } x ${item.nombre}`
            : nuevoDetalle +
              ` | ${
                element.medida === 'gr'
                  ? (element.cantidad + item.cantidad) * element.escala < 1000
                    ? (element.cantidad + item.cantidad) * element.escala
                    : ((element.cantidad + item.cantidad) * element.escala) /
                      1000
                  : (element.cantidad + item.cantidad) * element.escala
              } ${
                item.medida === 'gr' &&
                (element.cantidad + item.cantidad) * item.escala >= 1000
                  ? 'kg'
                  : item.medida
              } x ${item.nombre}`;
      } else {
        nuevoDetalle =
          index === 0
            ? nuevoDetalle +
              `${
                element.medida === 'gr'
                  ? element.cantidad * element.escala < 1000
                    ? element.cantidad * element.escala
                    : (element.cantidad * element.escala) / 1000
                  : element.cantidad * element.escala
              } ${
                element.medida === 'gr' &&
                (element.cantidad + element.cantidad) * element.escala >= 1000
                  ? 'kg'
                  : element.medida
              } x ${element.nombre}`
            : nuevoDetalle +
              ` | ${
                element.medida === 'gr'
                  ? element.cantidad * element.escala < 1000
                    ? element.cantidad * element.escala
                    : (element.cantidad * element.escala) / 1000
                  : element.cantidad * element.escala
              } ${
                element.medida === 'gr' &&
                (element.cantidad + element.cantidad) * element.escala >= 1000
                  ? 'kg'
                  : element.medida
              } x ${element.nombre}`;
      }
      setDetalle(nuevoDetalle);
    });
    actualizarCarrito(
      pais,
      pedido,
      descuento,
      carrito,
      parseFloat(
        ((totalactual * 100 + item.cantidad * item.precio * 100) / 100).toFixed(
          2
        )
      ),
      nuevoDetalle
    ).then(() => {
      setActualizar(true);
    });
  };

  return (
    <Provider
      value={{
        pais,
        setPais,
        actualizar,
        setActualizar,
        loading,
        sucursal,
        setSucursal,
        pedidos,
        usuarioLogueado,
        setUsuarioLogueado,
        router,
        setNombreSucursal,
        email,
        setEmail,
        fechaEntrega,
        setFechaEntrega,
        indexFecha,
        setIndexFecha,
        isShowOptions,
        setIsShowOptions,
        carrito,
        setCarrito,
        total,
        setTotal,
        detalle,
        setDetalle,
        nuevoCarrito,
        clickeable,
        setClickeable,
        handleBorrar,
        anulado,
        setAnulado,
        modalAgregarProductos,
        setModalAgregarProductos,
        agregarProductoEnPedido,
        agregarProductosEnPedido,
        pedidoActual,
        SetPedidoActual,
        productos,
        totalDescuento,
        setTotalDescuento,
        modalAlert,
        setModalAlert,
        allPedidos,
        paymentMethod,
        setPaymentMethod,
        modalComprobante,
        setModalComprobante,
        currentPhoto,
        setCurrentPhoto,
        modalState,
        setModalState,
        modalDeleteProducts,
        setModalDeleteProducts,
        currentDeleteItem,
        setCurrentDeleteItem,
        montoCupon,
        setMontoCupon,
        checkedBills,
        setCheckedBills,
        facturas,
        setFacturas,
        setRealizoPago,
        pagos, 
        setPagos
      }}
    >
      {children}
    </Provider>
  );
}

export { AdminProvider, Consumer as AdminConsumer, AdminContext };
