import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

let analytics;

export function inicialize() {
  analytics = Analytics({
    app: 'awesome-app',
    plugins: [
      googleTagManager({
        containerId: process.env.NEXT_PUBLIC_GTM,
      }),
    ],
  });
}

export function trackpage() {
  if (!analytics) inicialize();
  analytics.page();
}

export async function track(tag, payload) {
  try {
    if (!analytics) inicialize();
    if (!tag) throw new Error('GTM track missing tag');
    if (!payload) throw new Error('GTM track missing payload');
    analytics.track(tag, payload);
  } catch (e) {
    console.error('track', e);
  }
}
