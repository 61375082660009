import '/styles/globals.css';
import '/styles/StylesBulma.scss';
import { trackpage } from 'services/gtm';
import { useEffect } from 'react';
import { AdminProvider } from 'Context';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    trackpage();
    router.events.on('routeChangeComplete', trackpage);
    return () => {
      router.events.off('routeChangeComplete', trackpage);
    };
  }, []);

  return (
    <AdminProvider>
      <Component {...pageProps} />
    </AdminProvider>
  );
}

export default MyApp;
